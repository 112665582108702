import React, { useEffect, useMemo, Suspense, lazy } from "react";
// import AuthProvider from './context/AuthContext';
import PrivateRoute from "./PrivateRoute";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import { Helmet } from "react-helmet";  // For SEO and document title

import Loader from "./components/Loader/Loader"; // Import your custom loader
import "./App.css";


const Teams = lazy(() => import("./components/Team/Teams"));
const SingleViewTeams = lazy(() => import("./components/Team/SingleViewTeams"));


const Login = lazy(() => import("./components/Authentacion/Login"));
const SignUp = lazy(() => import("./components/Authentacion/SignUp"));
const PlacesHome = lazy(() => import("./components/Places/PlacesHome"));
const PlacesDetails = lazy(() => import("./components/Places/PlacesDetails"));

const ResetEmail = lazy(() => import("./components/Authentacion/ResetEmail"));
const ResetPhone = lazy(() => import("./components/Authentacion/ResetPhone"));
const ResetEmailOTP = lazy(() =>
  import("./components/Authentacion/ResetEmailOTP")
);
const ResetEmailNewPassword = lazy(() =>
  import("./components/Authentacion/ResetEmailNewPassword")
);
const VerifyEmailOTP = lazy(() =>
  import("./components/Authentacion/VerifyEmailOTP")
);
const VerifyPhoneOTP = lazy(() =>
  import("./components/Authentacion/VerifyPhoneOTP")
);
const ChampionsHome = lazy(() => import("./components/Champion/ChampionsHome"));
const ChampionDetails = lazy(() =>
  import("./components/Champion/ChampionDetails")
);
// const ChampionDetails2 = lazy(() =>
//   import("./components/Champion/ChampionDetails2")
// );


const SingleViewPartner = lazy(() =>
  import("./components/Champion/SingleViewPartner")
);
const SingleViewPlace = lazy(() =>
  import("./components/Champion/SingleViewPlace")
);

const SingleViewMatches = lazy(() =>
  import("./components/Champion/SingleViewMatches")
);

// const PlayerProfile = lazy(() =>
//   import("./components/PlayerProfile/MyProfile")
// );
const PlayerProfile = lazy(() =>
  import("./components/PlayerProfile/EditProfile")
);

const MatchesHome = lazy(() => import("./components/Matches/MatchesList"));

const EmailTab = lazy(() => import("./components/Settings/Email"));
const PasswordTab = lazy(() => import("./components/Settings/PasswordTab"));
const VerificationPage = lazy(() =>
  import("./components/Verification/VerificationPage")
);
const TranslateTab = lazy(() => import("./components/Settings/translate"));
const OTPEmail = lazy(() => import("./components/Settings/OTPEmail"));

const TermsOfService = lazy(() => import("./components/PrivacyPolicy/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy/PrivacyPolicy"));

const Layout = lazy(() => import("./Layout/Layout"));

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "ar") {
    } else {
      document.body.classList.remove("rtl");
    }
  }, [i18n.language]);
  const routes = useMemo(
    () => (
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/ResetEmail" element={<ResetEmail />} />
        <Route path="/forgot-password-phone" element={<ResetPhone />} />
        <Route path="/ResetEmailOTP" element={<ResetEmailOTP />} />
        <Route
          path="/ResetEmailNewPassword"
          element={<ResetEmailNewPassword />}
        />
        <Route path="/VerifyEmailOTP" element={<VerifyEmailOTP />} />
        <Route path="/verify-phone-otp" element={<VerifyPhoneOTP />} />

        <Route
          path="/Layout"
          element={
              <Layout />
          }
        />


        <Route
          path="/TermsOfService"
          element={
              <TermsOfService />
          }
        />

        <Route
          path="/PrivacyPolicy"
          element={
              <PrivacyPolicy />
          }
        />

        <Route
          path="/ChampionDetails"
          element={
            <PrivateRoute>
              <ChampionDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/ChampionDetails"
          element={
            <PrivateRoute>
              <ChampionDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="SingleViewPartner"
          element={
            <PrivateRoute>
              <SingleViewPartner />
            </PrivateRoute>
          }
        />
        <Route
          path="SingleViewPlace"
          element={
            <PrivateRoute>
              <SingleViewPlace />
            </PrivateRoute>
          }
        />

        <Route
          path="SingleViewMatches"
          element={
            <PrivateRoute>
              <SingleViewMatches />
            </PrivateRoute>
          }
        />
        <Route
          path="/PlacesHome"
          element={
            <PrivateRoute>
              <PlacesHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/PlacesDetails"
          element={
            <PrivateRoute>
              <PlacesDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/ChampionsHome"
          element={
            <PrivateRoute>
              <ChampionsHome />
            </PrivateRoute>
          }
        />

        <Route
          path="/SingleViewPartner"
          element={
            <PrivateRoute>
              <SingleViewPartner />
            </PrivateRoute>
          }
        />
        <Route
          path="/Teams"
          element={
            <PrivateRoute>
              <Teams />
            </PrivateRoute>
          }
        />
        
        <Route path="/SingleViewTeams" element={<SingleViewTeams />} />
        {/* <Route
          path="/PlayerProfile"
          element={
            <PrivateRoute>
              <PlayerProfile />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/PlayerProfile"
          element={
            <PrivateRoute>
              <PlayerProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/MatchesHome"
          element={
            <PrivateRoute>
              <MatchesHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/OTPEmail"
          element={
            <PrivateRoute>
              <OTPEmail />
            </PrivateRoute>
          }
        />
        <Route
          path="/EmailTab"
          element={
            <PrivateRoute>
              <EmailTab />
            </PrivateRoute>
          }
        />
        <Route
          path="/PasswordTab"
          element={
            <PrivateRoute>
              <PasswordTab />
            </PrivateRoute>
          }
        />
        <Route
          path="/VerificationPage"
          element={
            <PrivateRoute>
              <VerificationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/TranslateTab"
          element={
            <PrivateRoute>
              <TranslateTab />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login"/>} />
      </Routes>
    ),
    []
  );
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        {routes}
        <ToastContainer />
      </Router>
    </Suspense>
  );
}

export default App;
